import React, { useEffect, useState, useContext } from "react";
import * as CONST from "../config/Constant";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

function AuthMenu() {
  const [eventStatus, setEventStatus] = useState("");
  const [dptStatus, setDptStatus] = useState(0);
  const [empStatus, setEmpStatus] = useState(0);
  const [policyStatus, setPolicyStatus] = useState(0);
  const [openingStatus, setOpeningStatus] = useState(0);
  const [birthdayStatus, setBirthdayStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [leaveStatus, setLeaveStatus] = useState(0);
  const [hrdStatus, setHrdStatus] = useState(0);
  const [supportStatus, setSupportStatus] = useState(0);
  const [libraryStatus, setLibraryStatus] = useState(0);
  const [exemptionStatus, setExemptionStatus] = useState(0);
  const [settingStatus, setSettingStatus] = useState(0);
  const [assetsStatus, setAssetsStatus] = useState(0);
  let department_id = '';
  let isToken = "";
  let is_head = 0;
  let is_admin = 0;
  let hr_head = 0;
  let portal_permissions = '';
  if (authUserData) {
    /*  console.log(authUserData[0]); */
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      department_id = authUserData[0].department_id ? authUserData[0].department_id : "";
      is_head = authUserData[0].is_head ? authUserData[0].is_head : 0;
      is_admin = authUserData[0].is_admin ? authUserData[0].is_admin : 0;
      hr_head = authUserData[0].hr_head ? authUserData[0].hr_head : 0;
      portal_permissions = authUserData[0].permissions ? authUserData[0].permissions : '';
      /*  console.log(portal_permissions); */
    }
  }
  async function checkPermitions(portal_permissions) {
    if (portal_permissions) {
      portal_permissions = JSON.parse(portal_permissions);
      setEventStatus(portal_permissions ? portal_permissions.eventStatus : 0);
      setDptStatus(portal_permissions ? portal_permissions.dptStatus : 0);
      setEmpStatus(portal_permissions ? portal_permissions.empStatus : 0);
      setPolicyStatus(portal_permissions ? portal_permissions.policyStatus : 0);
      setOpeningStatus(portal_permissions ? portal_permissions.openingStatus : 0);
      setBirthdayStatus(portal_permissions ? portal_permissions.birthdayStatus : 0);
      setHrdStatus(portal_permissions ? portal_permissions.hrdStatus : 0);
      setEmpStatus(portal_permissions ? portal_permissions.empStatus : 0);
      setSupportStatus(portal_permissions ? portal_permissions.supportStatus : 0);
      setLibraryStatus(portal_permissions ? portal_permissions.libraryStatus : 0);
      setExemptionStatus(portal_permissions ? portal_permissions.exemptionStatus : 0);
      setAssetsStatus(portal_permissions ? portal_permissions.assetsStatus : 0);
      setSettingStatus(portal_permissions ? portal_permissions.settingStatus : 0);
    }
  }
  useEffect(() => {
    checkPermitions(portal_permissions)
  }, [])

  return (
    <>
      {

        (is_head == 1 || is_admin == 1 || department_id == 5) ? (
          <>
            <li className="nav-item">
              <Link
                data-toggle="collapse"
                data-target="#Employee"
                aria-controls="Employee"
                aria-expanded="false"
                aria-label="Toggle navigation"
                to="#"
              /* to={CONST.ADMIN_PRE + "employee"} */
              >
                <span className="pr-2"> <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-square mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 10a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                  <path d="M6 21v-1a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v1"></path>
                  <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"></path>
                </svg>Employee</span>
                <div className="sub-nav-toggler">
                  <span>
                    <i className="lni lni-chevron-down"></i>{" "}
                  </span>
                </div>
              </Link>
              <ul className="sub-menu collapse" id="Employee">
                {
                  (is_head == 1 || is_admin == 1 || hrdStatus == 1) && (
                    <li>
                      <Link to={CONST.ADMIN_PRE + "employee"}> <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                      </svg>All Employees </Link>
                    </li>
                  )
                }
                {
                  (is_head == 1 || is_admin == 1) && (
                    <li>
                      <Link to={CONST.ADMIN_PRE + "employee-leaves"}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                      </svg> Leaves </Link>
                    </li>
                  )
                }
                {
                  (is_head == 1 || is_admin == 1) && (
                    <li>
                      <Link to={CONST.ADMIN_PRE + "employee-comp-off"}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                      </svg> Comp-Off </Link>
                    </li>
                  )
                }
              </ul>
            </li>
          </>
        )

          : (
            <>
              <li className="nav-item">
                <Link
                  data-toggle="collapse"
                  data-target="#Employee"
                  aria-controls="Employee"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  to="#"
                /* to={CONST.ADMIN_PRE + "employee"} */
                >
                  <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plane-tilt mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M14.5 6.5l3 -2.9a2.05 2.05 0 0 1 2.9 2.9l-2.9 3l2.5 7.5l-2.5 2.55l-3.5 -6.55l-3 3v3l-2 2l-1.5 -4.5l-4.5 -1.5l2 -2h3l3 -3l-6.5 -3.5l2.5 -2.5l7.5 2.5z"></path>
                  </svg>Leaves & Comp Off</span>
                  <div className="sub-nav-toggler">
                    <span>
                      <i className="lni lni-chevron-down"></i>{" "}
                    </span>
                  </div>
                </Link>
                <ul className="sub-menu collapse" id="Employee">
                  <li>
                    <Link to={CONST.ADMIN_PRE + "employee-leaves"}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                    </svg> Employee Leaves </Link>
                  </li>

                </ul>
              </li>
            </>
          )
      }


      {

        (is_admin == 1 || department_id == 5)
          ? (
            <>
              <li className="nav-item">
                <Link
                  className="page-scroll "
                  href="#"
                  data-toggle="collapse"
                  data-target="#HRD"
                  aria-controls="HRD"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart-handshake mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                    <path d="M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25"></path>
                    <path d="M12.5 15.5l2 2"></path>
                    <path d="M15 13l2 2"></path>
                  </svg>HRD  </span>
                  <div className="sub-nav-toggler">
                    <span>
                      <i className="lni lni-chevron-down"></i>{" "}
                    </span>
                  </div>
                </Link>
                <div className="sub-menu collapse mega_menu" id="HRD">

                  <div>
                    <span className="menu_heading">Policy</span>
                    <ul>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "policy-type"}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg>Policy Type
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "policies"}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg>Policy
                        </Link>
                      </li>
                    </ul>
                  </div>


                  <div>
                    <span className="menu_heading">Events</span>
                    <ul>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "events"}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg> Events
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "event-category"}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg> Events Category
                        </Link>
                      </li>
                    </ul>
                  </div>


                  <div>
                    <span className="menu_heading">Openings</span>
                    <ul>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "openings"}
                          title="openings"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg> Openings
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <span className="menu_heading">Department</span>
                    <ul>

                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "department"}
                          title="department"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg>Department
                        </Link>
                      </li>

                    </ul>
                  </div>
                  <hr />
                  <div>
                    <span className="menu_heading"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                    </svg>Designation</span>
                    <ul>

                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "designation"}
                          title="designation"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg> Designation
                        </Link>
                      </li>

                    </ul>
                  </div>
                  <div>
                    <span className="menu_heading">Testimonials</span>
                    <ul>

                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "testimonials"}
                          title="Testimonials"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg> Testimonials
                        </Link>
                      </li>

                    </ul>
                  </div>
                  <div>
                    <span className="menu_heading">Birthday</span>
                    <ul>

                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "birthday"}
                          title="birthday"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg>Birthday
                        </Link>
                      </li>

                    </ul>
                  </div>
                  <div>
                    <span className="menu_heading">Holidays</span>
                    <ul>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          aria-current="page"
                          to={CONST.ADMIN_PRE + "holidays"}
                          title="Holidays"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                          </svg> Holidays
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </>

          ) :
          (

            (department_id == 8 || is_admin == 1) ?
              (
                <>
                  <li className="nav-item">
                    <Link
                      data-toggle="collapse"
                      data-target="#dept"
                      aria-controls="Employee"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      to="#"
                    /* to={CONST.ADMIN_PRE + "employee"} */
                    >
                      <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-imac-cog mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 17h-8a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8"></path>
                        <path d="M3 13h13"></path>
                        <path d="M8 21h4"></path>
                        <path d="M10 17l-.5 4"></path>
                        <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                        <path d="M19.001 15.5v1.5"></path>
                        <path d="M19.001 21v1.5"></path>
                        <path d="M22.032 17.25l-1.299 .75"></path>
                        <path d="M17.27 20l-1.3 .75"></path>
                        <path d="M15.97 17.25l1.3 .75"></path>
                        <path d="M20.733 20l1.3 .75"></path>
                      </svg>Network</span>
                      <div className="sub-nav-toggler">
                        <span>
                          <i className="lni lni-chevron-down"></i>{" "}
                        </span>
                      </div>
                    </Link>
                    <ul className="sub-menu collapse" id="dept">
                      <li>
                        <Link to={CONST.ADMIN_PRE + "department"}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                        </svg>Department </Link>
                      </li>
                      <li>
                        <Link to={CONST.ADMIN_PRE + "designation"}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                        </svg> Designation </Link>
                      </li>

                    </ul>
                  </li>
                </>
              ) : (
                <></>
              )

          )

      }
      {/* {
        (is_head == 1 || is_admin == 1 || hrdStatus == 1) && (
          <>
            <li className="nav-item">
              <Link
                className="page-scroll "
                href="#"
                data-toggle="collapse"
                data-target="#Department"
                aria-controls="Department"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="pr-2">Department</span>
                <div className="sub-nav-toggler">
                  <span>
                    <i className="lni lni-chevron-down"></i>{" "}
                  </span>
                </div>
              </Link>
              <ul className="sub-menu collapse" id="Department">
                <li className="nav-item">
                  <Link
                    className="page-scroll"
                    aria-current="page"
                    to={CONST.ADMIN_PRE + "department"}
                  >
                    Department
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="page-scroll"
                    aria-current="page"
                    to={CONST.ADMIN_PRE + "designation"}
                  >
                    Designation
                  </Link>
                </li>
              </ul>
            </li>
          </>
        )} */}


      {/* {
        (is_admin == 1 || is_head == 1) && (
          (libraryStatus == 1) && (
            <li className="nav-item">
              <Link
                className="page-scroll"
                aria-current="page"
                to={CONST.ADMIN_PRE + "library"}
                title="library"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-grid-pattern mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                  <path d="M10 8v8"></path>
                  <path d="M14 8v8"></path>
                  <path d="M8 10h8"></path>
                  <path d="M8 14h8"></path>
                </svg>Library</Link>
            </li>
          )
        )
      } */}
      {/* {
        (
          (is_head == 1 || is_admin == 1)
          && (
            (exemptionStatus == 1) && (

              <li className="nav-item">
                <Link
                  className="page-scroll "
                  href="#"
                  data-toggle="collapse"
                  data-target="#Exemption"
                  aria-controls="Exemption"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-coin-rupee mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                    <path d="M15 8h-6h1a3 3 0 0 1 0 6h-1l3 3"></path>
                    <path d="M9 11h6"></path>
                  </svg>Account </span>
                  <div className="sub-nav-toggler">
                    <span>
                      <i className="lni lni-chevron-down"></i>{" "}
                    </span>
                  </div>
                </Link>
                <ul className="sub-menu collapse" id="Exemption">
                  <li className="nav-item">
                    <Link
                      className="page-scroll"
                      aria-current="page"
                      to={CONST.ADMIN_PRE + "exemption-list"}
                      title="exemption-list"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                      </svg>All Tax Exemption
                    </Link>
                  </li>
                </ul>
              </li>
            )
          ))
      } */}

      {
        (department_id == 8 || is_admin == 1) && (
          <>
            <li className="nav-item">
              <Link
                className="page-scroll "
                href="#"
                data-toggle="collapse"
                data-target="#sub-nav2"
                aria-controls="sub-nav2"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checklist mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8"></path>
                  <path d="M14 19l2 2l4 -4"></path>
                  <path d="M9 8h4"></path>
                  <path d="M9 12h2"></path>
                </svg> Assets</span>
                <div className="sub-nav-toggler">
                  <span>
                    <i className="lni lni-chevron-down"></i>{" "}
                  </span>
                </div>
              </Link>
              <ul className="sub-menu collapse" id="sub-nav2">
                <li className="nav-item">
                  <Link
                    className="page-scroll"
                    aria-current="page"
                    to={CONST.ADMIN_PRE + "assets-brand"}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                    </svg> Assets Brand
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="page-scroll"
                    aria-current="page"
                    to={CONST.ADMIN_PRE + "assets-type"}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                    </svg> Assets Type
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="page-scroll"
                    aria-current="page"
                    to={CONST.ADMIN_PRE + "assets-management"}> <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                    </svg>Assets Management
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="page-scroll"
                    aria-current="page"
                    to={CONST.ADMIN_PRE + "employee-assets-list"}> <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                    </svg>Employee Assets List
                  </Link>
                </li>
              </ul>
            </li>
          </>
        )
      }

      <li className="nav-item">
        <Link
          className="page-scroll"
          aria-current="page"
          to={CONST.ADMIN_PRE + "tickets"}
          title="support"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail-bolt mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M13 19h-8a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5.5"></path>
            <path d="M3 7l9 6l9 -6"></path>
            <path d="M19 16l-2 3h4l-2 3"></path>
          </svg> Support
        </Link>
      </li>
      {/* Report */}
      <li className="nav-item">
        <Link
          className="page-scroll "
          href="#"
          data-toggle="collapse"
          data-target="#report-menu"
          aria-controls="report-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="pr-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-infographic mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M7 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
              <path d="M7 3v4h4"></path>
              <path d="M9 17l0 4"></path>
              <path d="M17 14l0 7"></path>
              <path d="M13 13l0 8"></path>
              <path d="M21 12l0 9"></path>
            </svg>Reports  </span>
          <div className="sub-nav-toggler">
            <span>
              <i className="lni lni-chevron-down"></i>{" "}
            </span>
          </div>
        </Link>
        <div className="sub-menu collapse mega_menu" id="report-menu">
          <div>
            <span className="menu_heading">Employee</span>
            <ul>
              <li className="nav-item">
                <Link
                  className="page-scroll"
                  aria-current="page"
                  to={CONST.ADMIN_PRE + "report/employee-leaves-comp-off"}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                  </svg> Leaves & Comp-Off
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </li>
      {/* Setting */}
      {
        (
          (is_head == 1 || is_admin == 1)
          &&
          (
            (settingStatus == 1) && (
              <li className="nav-item">
                <Link
                  className="page-scroll "
                  href="#"
                  data-toggle="collapse"
                  data-target="#sub-nav2"
                  aria-controls="sub-nav2"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brightness-2 mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                    <path d="M6 6h3.5l2.5 -2.5l2.5 2.5h3.5v3.5l2.5 2.5l-2.5 2.5v3.5h-3.5l-2.5 2.5l-2.5 -2.5h-3.5v-3.5l-2.5 -2.5l2.5 -2.5z"></path>
                  </svg>Setting</span>
                  <div className="sub-nav-toggler">
                    <span>
                      <i className="lni lni-chevron-down"></i>{" "}
                    </span>
                  </div>
                </Link>
                <ul className="sub-menu collapse" id="sub-nav2">
                  <li className="nav-item">
                    <Link
                      className="page-scroll"
                      aria-current="page"
                      to={CONST.ADMIN_PRE + "permissions"}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                      </svg>Manage Permissions
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="page-scroll"
                      aria-current="page"
                      to={CONST.ADMIN_PRE + "roles-responsibility"}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                      </svg> Roles & Responsibility
                    </Link>
                  </li>

                </ul>
              </li>
            )
          )
        )
      }
    </>
  );
}

export default AuthMenu;
