import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext';
import * as  CONST from "../../component/config/Constant"
import axios from "axios";

function WithoutAuthMenu() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [policies, setPolicies] = useState("");

  let isToken = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }

  useEffect(() => {
    fetchPolicyDetails();
  }, []);

  const fetchPolicyDetails = async () => {
    await axios
      .get(CONST.WEB_GET_POLICY_LIST,
        {
          page_number: 1,
          limit: 10
        })
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          console.log(response.data.data);
          setPolicies(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  }

  return (
    <>

      <li className="nav-item front-mega-menu">
        <Link
          className="page-scroll "
          href="#"
          data-toggle="collapse"
          data-target="#HRD"
          aria-controls="HRD"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-accessible mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
            <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1"></path>
            <circle cx="12" cy="7.5" r=".5" fill="currentColor"></circle>
          </svg>Policies</span>
          <div className="sub-nav-toggler">
            <span>
              <i className="lni lni-chevron-down"></i>{" "}
            </span>
          </div>
        </Link>
        <div className="sub-menu collapse mega_menu" id="HRD">

          <div className='menu_section'>
            <span className="menu_heading">Policies</span>
            <ul>
              <div className="row policies-header">
                {
                  policies && policies.map((policy, index) => {
                    if (policy.e_policy_type.name == 'Textual Policies') {
                      return (
                        <>
                          <div className="col-md-4">
                            <li className="nav-item">
                              <Link
                                className="page-scroll"
                                aria-current="page"
                                to={"/policy/" + policy.id + "/" + convertToSlug(policy.policy_name)}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                  <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                                </svg> {policy.policy_name}
                              </Link>
                            </li>
                          </div>
                        </>
                      )

                    }
                  })
                }


              </div>
            </ul>
          </div>

          <div>
            <span className="menu_heading">Download Policies</span>
            <ul>
              <div className="row policies-header">
                {
                  policies && policies.map((policy, index) => {
                    if (policy.e_policy_type.name == 'Download Policies') {
                      return (
                        <>
                          <div className="col-12 col-md-6">
                            <li className="nav-item">
                              <Link
                                className="page-scroll"
                                aria-current="page"
                                to={CONST.HOST + policy.policy_document_path}
                                target="_blank"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                  <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
                                </svg> {policy.policy_name}
                              </Link>
                            </li>
                          </div>
                        </>
                      )

                    }
                  })
                }
              </div>
            </ul>
          </div>



        </div>
      </li>
      <li className="nav-item">
        <Link
          data-toggle="collapse"
          data-target="#Employee"
          aria-controls="Employee"
          aria-expanded="false"
          aria-label="Toggle navigation"
          to="#"
        /* to={CONST.ADMIN_PRE + "employee"} */
        >
          <span className="pr-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-desktop-code mr-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12.5 16h-8.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8"></path>
            <path d="M7 20h4"></path>
            <path d="M9 16v4"></path>
            <path d="M20 21l2 -2l-2 -2"></path>
            <path d="M17 17l-2 2l2 2"></path>
          </svg>Training</span>
          <div className="sub-nav-toggler">
            <span>
              <i className="lni lni-chevron-down"></i>{" "}
            </span>
          </div>
        </Link>
        <ul className="sub-menu collapse" id="Employee">

          <li>
            <Link className="page-scroll" to={"/business-communication"}>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path>
              </svg>Business Communication
            </Link>
          </li>
        </ul>
      </li>
      {/* <li className="nav-item">
        <Link className="page-scroll" to={"/events"}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mood-happy mr-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
            <path d="M9 9l.01 0"></path>
            <path d="M15 9l.01 0"></path>
            <path d="M8 13a4 4 0 1 0 8 0h-8"></path>
          </svg> Events{" "}
        </Link>
      </li> */}

      <li className="nav-item">
        <Link className="page-scroll" to={"/careers"}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-school mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
            <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
          </svg>Career
        </Link>
      </li>
      {/* <li className="nav-item">
        <Link className="page-scroll" to={"/testimonials"}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-float-right mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 5m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
            <path d="M4 7l6 0"></path>
            <path d="M4 11l6 0"></path>
            <path d="M4 15l16 0"></path>
            <path d="M4 19l16 0"></path>
          </svg>Testimonials
        </Link>
      </li> */}

      {/* <li className="nav-item">
        <Link className="page-scroll" to={"/technologies"}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-grid-pattern mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
            <path d="M10 8v8"></path>
            <path d="M14 8v8"></path>
            <path d="M8 10h8"></path>
            <path d="M8 14h8"></path>
          </svg> Technologies
        </Link>
      </li> */}
      
      <li className="nav-item">
        <Link className="page-scroll" to={"/holiday"}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-grid-pattern mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
            <path d="M10 8v8"></path>
            <path d="M14 8v8"></path>
            <path d="M8 10h8"></path>
            <path d="M8 14h8"></path>
          </svg> Holiday
        </Link>
      </li>     
      {!isToken && (
        <>
          <li className="nav-item">
            {" "}
            <Link to={"/login"} className="page-scroll">
              <i className="lni lni-lock mr-1"></i> Login
            </Link>
          </li>
        </>
      )
      }
    </>
  )
}

export default WithoutAuthMenu