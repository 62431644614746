import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer footer-style-1 mt-auto">
        <div className="container">
          <div className="widget-wrapper">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/eluminous-pvt-ltd.png"
                        }

                        alt="logo"
                      />
                    </Link>
                  </div>


                </div>
              </div>
              <div className="col-xl-2 offset-xl-1 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h6 className="mb-2">Quick Link</h6>
                  <ul className="links pl-0">
                    <li> <Link to="/">Home</Link> </li>
                    <li> <Link to={"/testimonials"}>Testimonials</Link> </li>
                    <li> <Link to={"/technologies"}>Technologies</Link> </li>
                  </ul>
                </div>
              </div>
              {/*  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h6 className="mb-2">Policies</h6>
                  <ul className="links pl-0">
                    <li> <a href="#0">About Page</a> </li>
                    <li> <a href="#0">Team Page</a> </li>
                    <li> <a href="#0">Blog</a> </li>

                  </ul>
                </div>
              </div> */}
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h6 className="mb-2">Contact</h6>

                  <ul className="pl-0 contact_color">
                    <li className="mb-2">
                      <i className="lni lni-phone"></i> <a href="tel:14844019147">USA: +1 484 401 9147</a>
                    </li>
                    <li className="two_num">
                      <i className="lni lni-phone"></i> <a href="tel:912532382566"> India: +91 253 238 2566 </a> /
                      <a href="tel:918208222939"> 8208222939 </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-wrapper">
            <p>
              {" "}
              Copyright © 2003 -{new Date().getFullYear()}{" "}
              <Link
                to={"https://eluminousetechnologies.com/"}
                target={"_blank"}
                className="link-secondary"
              >
                eLuminous technologies Pvt. Ltd.{" "}
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
<script
  src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js"
  integrity="sha512-Eak/29OTpb36LLo2r47IpVzPBLXnAMPAVypbSZiZ4Qkf8p/7S/XRG5xp7OKWPPYfJT6metI+IORkR5G8F900+g=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
></script>;
export default Footer;
